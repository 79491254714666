import { navigate } from "gatsby"
import { scroller } from "react-scroll"

const handleAnchorLink = (
  pageUrl: string,
  element: string,
  setScrollToAnchorContext: (element: string) => void
) => {
  const url = typeof window !== "undefined" ? window.location.pathname : ""

  if (pageUrl && element && pageUrl === url) {
    scroller.scrollTo(element, {
      duration: 800,
      spy: true,
      smooth: true,
    })
  } else {
    if (element && setScrollToAnchorContext) {
      setScrollToAnchorContext(element)
    }
    navigate(pageUrl)
  }
}

export default handleAnchorLink
