import React from "react"

// Constants
import { xmlNamespace } from "../constants/svgUrl"

// Styling
import colors from "../styles/colors"

interface IProps {
  simple?: boolean
  withText?: boolean
}

const Logo: React.FCS<IProps> = ({
  simple = false,
  className,
  withText = true,
}) =>
  withText ? (
    <svg
      width={121}
      height={32}
      viewBox="0 0 121 32"
      fill="none"
      xmlns={xmlNamespace}
    >
      <path
        d="M15.0748 16.8778H5.42643L1.99584 20.3128C-0.665278 22.9739 -0.665278 27.2872 1.99584 29.9483C4.65695 32.6094 8.97115 32.6094 11.6313 29.9483L15.0748 26.5048L15.0739 16.8778H15.0748Z"
        fill={colors.main["300"]}
      />
      <path
        d="M25.1062 2.39313C26.3023 2.39313 27.4269 2.8586 28.2722 3.70489C30.0184 5.45107 30.0184 8.29178 28.2722 10.037L25.5773 12.732H19.2132V6.43182L21.9401 3.70489C22.7855 2.85954 23.9101 2.39313 25.1062 2.39313ZM25.1062 0.057373C23.3628 0.057373 21.6185 0.722181 20.288 2.05274L16.8765 5.46423V15.0677H26.5449L29.9234 11.6892C32.5846 9.02805 32.5846 4.71385 29.9234 2.05274C28.5929 0.722181 26.8495 0.057373 25.1052 0.057373H25.1062Z"
        fill={colors.main["300"]}
      />
      <path
        d="M29.9244 29.9643C32.5855 27.3032 32.5855 22.989 29.9244 20.3278L26.4809 16.8853C26.4809 16.8853 26.2675 16.8853 26.1509 16.8853L16.8764 16.8778L16.8765 26.5528L20.288 29.9643C22.9491 32.6254 27.2633 32.6254 29.9234 29.9643H29.9244Z"
        fill={colors.purpleLighter}
      />
      <path
        d="M15.0757 15.0837V5.80927V5.46635L11.6322 2.03575C8.97113 -0.625361 4.65692 -0.625361 1.99675 2.03575C-0.664363 4.69687 -0.664363 9.01107 1.99675 11.6712L5.40824 15.0827H15.0766L15.0757 15.0837Z"
        fill={colors.purpleLight}
      />
      <path
        d="M44.941 22.2504C45.5294 22.2504 46.0109 22.2058 46.3854 22.1166C46.7777 22.0096 47.0897 21.8759 47.3216 21.7154C47.5534 21.5371 47.7139 21.332 47.803 21.1002C47.8922 20.8684 47.9368 20.6098 47.9368 20.3245C47.9368 19.7182 47.6515 19.2189 47.0808 18.8266C46.5102 18.4164 45.5294 17.9796 44.1385 17.5159C43.5322 17.3019 42.9259 17.0612 42.3196 16.7937C41.7133 16.5084 41.1694 16.1607 40.688 15.7505C40.2065 15.3226 39.8142 14.8143 39.5111 14.2259C39.2079 13.6196 39.0563 12.8885 39.0563 12.0325C39.0563 11.1766 39.2168 10.4098 39.5378 9.73217C39.8588 9.03671 40.3135 8.44825 40.902 7.96678C41.4904 7.48531 42.2037 7.11975 43.0418 6.8701C43.8799 6.60261 44.825 6.46887 45.8771 6.46887C47.1254 6.46887 48.2042 6.60261 49.1137 6.8701C50.0231 7.13758 50.7721 7.43181 51.3605 7.75279L50.1569 11.0428C49.6397 10.7753 49.0602 10.5435 48.4182 10.3474C47.7941 10.1334 47.0362 10.0264 46.1446 10.0264C45.146 10.0264 44.4238 10.1691 43.978 10.4544C43.55 10.7219 43.3361 11.1409 43.3361 11.7115C43.3361 12.0504 43.4163 12.3357 43.5768 12.5675C43.7373 12.7993 43.9602 13.0133 44.2455 13.2094C44.5486 13.3878 44.8875 13.5572 45.2619 13.7177C45.6542 13.8603 46.0822 14.0119 46.5459 14.1724C47.5088 14.529 48.3469 14.8857 49.0602 15.2423C49.7735 15.5811 50.3619 15.9824 50.8256 16.446C51.3071 16.9096 51.6637 17.4535 51.8955 18.0776C52.1273 18.7018 52.2432 19.4596 52.2432 20.3512C52.2432 22.081 51.6369 23.4273 50.4244 24.3902C49.2118 25.3353 47.384 25.8079 44.941 25.8079C44.1207 25.8079 43.3806 25.7544 42.7208 25.6474C42.0611 25.5582 41.4726 25.4423 40.9555 25.2997C40.4562 25.157 40.0193 25.0054 39.6448 24.845C39.2881 24.6845 38.985 24.5329 38.7354 24.3902L39.9123 21.0734C40.4651 21.3766 41.1427 21.653 41.9451 21.9026C42.7654 22.1345 43.764 22.2504 44.941 22.2504Z"
        fill={colors.main["200"]}
      />
      <path
        d="M62.7027 25.8079C61.4009 25.8079 60.2775 25.6296 59.3324 25.2729C58.3873 24.8984 57.6027 24.3902 56.9785 23.7483C56.3722 23.0885 55.9175 22.3128 55.6144 21.4212C55.329 20.5117 55.1864 19.5131 55.1864 18.4254V6.89685H59.3591V18.0776C59.3591 18.8266 59.4394 19.4685 59.5999 20.0035C59.7782 20.5206 60.01 20.9486 60.2953 21.2874C60.5985 21.6084 60.9551 21.8402 61.3652 21.9829C61.7932 22.1255 62.2569 22.1969 62.7562 22.1969C63.7726 22.1969 64.5929 21.8848 65.217 21.2607C65.859 20.6366 66.1799 19.5755 66.1799 18.0776V6.89685H70.3527V18.4254C70.3527 19.5131 70.2011 20.5117 69.898 21.4212C69.5948 22.3306 69.1312 23.1152 68.507 23.775C67.8829 24.417 67.0894 24.9163 66.1264 25.2729C65.1635 25.6296 64.0222 25.8079 62.7027 25.8079Z"
        fill={colors.main["200"]}
      />
      <path
        d="M79.2874 6.89685C79.6084 7.48531 79.974 8.21643 80.3841 9.09021C80.8121 9.94615 81.249 10.8823 81.6948 11.8988C82.1584 12.8974 82.6131 13.9227 83.059 14.9748C83.5048 16.0269 83.9238 17.0166 84.3161 17.9439C84.7084 17.0166 85.1275 16.0269 85.5733 14.9748C86.0191 13.9227 86.4649 12.8974 86.9107 11.8988C87.3743 10.8823 87.8112 9.94615 88.2214 9.09021C88.6494 8.21643 89.0238 7.48531 89.3448 6.89685H93.1431C93.3214 8.12727 93.4819 9.50926 93.6245 11.0428C93.785 12.5586 93.9188 14.1456 94.0258 15.804C94.1506 17.4446 94.2576 19.0941 94.3467 20.7525C94.4537 22.4109 94.5429 23.9712 94.6142 25.4334H90.5485C90.495 23.6324 90.4237 21.6708 90.3345 19.5488C90.2453 17.4268 90.1116 15.2869 89.9333 13.1292C89.6123 13.8782 89.2556 14.7073 88.8633 15.6168C88.471 16.5262 88.0787 17.4357 87.6864 18.3451C87.3119 19.2546 86.9464 20.1283 86.5897 20.9664C86.2331 21.7867 85.9299 22.4911 85.6803 23.0796H82.7647C82.5151 22.4911 82.2119 21.7867 81.8553 20.9664C81.4986 20.1283 81.1242 19.2546 80.7318 18.3451C80.3574 17.4357 79.974 16.5262 79.5817 15.6168C79.1894 14.7073 78.8327 13.8782 78.5117 13.1292C78.3334 15.2869 78.1997 17.4268 78.1105 19.5488C78.0214 21.6708 77.95 23.6324 77.8965 25.4334H73.8308C73.9021 23.9712 73.9824 22.4109 74.0715 20.7525C74.1785 19.0941 74.2855 17.4446 74.3925 15.804C74.5173 14.1456 74.6511 12.5586 74.7937 11.0428C74.9542 9.50926 75.1236 8.12727 75.3019 6.89685H79.2874Z"
        fill={colors.main["200"]}
      />
      <path
        d="M103.293 6.89685C103.614 7.48531 103.98 8.21643 104.39 9.09021C104.818 9.94615 105.255 10.8823 105.7 11.8988C106.164 12.8974 106.619 13.9227 107.065 14.9748C107.51 16.0269 107.929 17.0166 108.322 17.9439C108.714 17.0166 109.133 16.0269 109.579 14.9748C110.025 13.9227 110.47 12.8974 110.916 11.8988C111.38 10.8823 111.817 9.94615 112.227 9.09021C112.655 8.21643 113.029 7.48531 113.35 6.89685H117.149C117.327 8.12727 117.487 9.50926 117.63 11.0428C117.791 12.5586 117.924 14.1456 118.031 15.804C118.156 17.4446 118.263 19.0941 118.352 20.7525C118.459 22.4109 118.548 23.9712 118.62 25.4334H114.554C114.501 23.6324 114.429 21.6708 114.34 19.5488C114.251 17.4268 114.117 15.2869 113.939 13.1292C113.618 13.8782 113.261 14.7073 112.869 15.6168C112.477 16.5262 112.084 17.4357 111.692 18.3451C111.317 19.2546 110.952 20.1283 110.595 20.9664C110.239 21.7867 109.935 22.4911 109.686 23.0796H106.77C106.521 22.4911 106.217 21.7867 105.861 20.9664C105.504 20.1283 105.13 19.2546 104.737 18.3451C104.363 17.4357 103.98 16.5262 103.587 15.6168C103.195 14.7073 102.838 13.8782 102.517 13.1292C102.339 15.2869 102.205 17.4268 102.116 19.5488C102.027 21.6708 101.956 23.6324 101.902 25.4334H97.8363C97.9077 23.9712 97.9879 22.4109 98.0771 20.7525C98.1841 19.0941 98.2911 17.4446 98.3981 15.804C98.5229 14.1456 98.6566 12.5586 98.7993 11.0428C98.9598 9.50926 99.1292 8.12727 99.3075 6.89685H103.293Z"
        fill={colors.main["200"]}
      />
    </svg>
  ) : (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={simple ? colors.white : "none"}
      xmlns={xmlNamespace}
    >
      <path
        d="M15.1134 16.9033H5.42084L2.00082 20.3233C-0.666942 22.9911 -0.666942 27.3152 2.00082 29.9829C4.66859 32.6507 8.99358 32.6507 11.6604 29.9829L15.1125 26.5308V16.9033H15.1134Z"
        fill={simple ? colors.white : colors.main["300"]}
      />
      <path
        d="M25.1692 2.35918C26.3683 2.35918 27.4957 2.8258 28.3432 3.67421C30.0938 5.42476 30.0938 8.27257 28.3432 10.0222L25.6415 12.7239H19.2615V6.40796L21.9952 3.67421C22.8427 2.82675 23.9701 2.35918 25.1692 2.35918ZM25.1692 0.0175781C23.4215 0.0175781 21.6728 0.684049 20.339 2.01793L16.9189 5.43795V15.0655H26.6115L29.9985 11.6785C32.6663 9.01069 32.6663 4.6857 29.9985 2.01793C28.6647 0.684049 26.9169 0.0175781 25.1683 0.0175781H25.1692Z"
        fill={simple ? colors.white : colors.main["300"]}
      />
      <path
        d="M29.9995 29.9993C32.6673 27.3316 32.6673 23.0066 29.9995 20.3388L26.5474 16.8877C26.4447 16.9282 26.3334 16.9518 26.2165 16.9518H16.9189V26.5793L20.339 29.9993C23.0067 32.6671 27.3317 32.6671 29.9985 29.9993H29.9995Z"
        fill={simple ? colors.white : colors.purpleLighter}
      />
      <path
        d="M15.1134 15.0814V5.78377C15.1134 5.68479 15.1332 5.59147 15.1625 5.50192L11.6614 2.00082C8.99361 -0.666942 4.66863 -0.666942 2.0018 2.00082C-0.665965 4.66859 -0.665965 8.99358 2.0018 11.6604L5.42182 15.0804H15.1144L15.1134 15.0814Z"
        fill={simple ? colors.white : colors.purpleLight}
      />
    </svg>
  )

export default Logo
