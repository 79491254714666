module.exports = {
  "en-nl": {
    default: false,
    path: "en",
    locale: "en-nl",
    siteLanguage: "en",
    ogLang: "en_NL",
  },
  "nl-nl": {
    default: true,
    path: "nl",
    locale: "nl-nl",
    siteLanguage: "nl",
    ogLang: "de_NL",
  },
}
