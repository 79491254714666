export const paths = {
  method: "/method",
  home: "/",
  demo: "/contact",
  platform: "/platform",
  evaluation: "/platform/evaluation",
  resources: "/resources",
  services: "/services",
  manifesto: "/services/manifesto",
  missionStatement: "/services/mission-statement",
  cultureSupport: "/services/culture-support",
  employeeJourney: "/services/employee-journey",
  feedbackTraining: "/services/feedback-training",
  teamLeadTraining: "/services/team-lead-training",
  career: "/services/career-framework",
  blog: "/blog",
  about: "/about",
  faq: "/faq",
  privacy: "/privacy",
  terms: "/terms",
}

export const getUrl = (link?: string) => {
  if (link === "form") return paths.demo
  if (link === "manifesto") return paths.manifesto
  if (link === "career") return paths.career
  if (link === "platform") return paths.platform
  if (link === "blog") return paths.blog
  if (link === "resources") return paths.resources
  if (link === "services") return paths.services
  if (link === "method") return paths.method
  if (link === "about") return paths.about
  if (link === "mission") return paths.missionStatement
  if (link === "support") return paths.cultureSupport
  if (link === "employee") return paths.employeeJourney
  if (link === "feedback") return paths.feedbackTraining
  if (link === "teamlead") return paths.teamLeadTraining
  return "/"
}

export const getNavItems = (isDutch: boolean) => {
  if (!isDutch)
    return [
      {
        type: "internal",
        title: "Method",
        to: paths.method,
      },
      {
        type: "internal",
        title: "Services",
        to: paths.services,
      },
      {
        type: "internal",
        title: "Platform",
        to: paths.platform,
      },
      {
        type: "internal",
        title: "Resources",
        to: paths.resources,
      },
      {
        type: "internal",
        title: "Blog",
        to: paths.blog,
      },
      {
        type: "internal",
        title: "About",
        to: paths.about,
      },
      {
        type: "localization",
        title: "Localization",
      },
      {
        type: "external",
        title: "Log in",
        to: "https://app.getsumm.co",
      },
    ]

  return [
    {
      type: "internal",
      title: "Methode",
      to: paths.method,
    },
    {
      type: "internal",
      title: "Diensten",
      to: paths.services,
    },
    {
      type: "internal",
      title: "Platform",
      to: paths.platform,
    },
    {
      type: "internal",
      title: "Resources",
      to: paths.resources,
    },
    {
      type: "internal",
      title: "Blog",
      to: paths.blog,
    },
    {
      type: "internal",
      title: "Ons verhaal",
      to: paths.about,
    },
    {
      type: "localization",
      title: "Localization",
    },
    {
      type: "external",
      title: "Log in",
      to: "https://app.getsumm.co",
    },
  ]
}
