import { css } from "styled-components"

// Styling
import { fonts } from "./fonts"
import mq from "./breakpoints"

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
}

export const underlinedSupport = css<{ underlineColor: string }>`
  .underlined {
    display: inline-block;
    border-bottom: 6px solid ${({ underlineColor }) => underlineColor};
    line-height: 0.9;
  }
`

// Text styles
export default {
  titleXL: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 36px;
    font-weight: ${fontWeights.bold};
    line-height: 1.19;

    ${mq.from.M`
      font-size: 64px;
    `}
  `,
  titleL: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 36px;
    font-weight: ${fontWeights.bold};
    line-height: 1.12;

    ${mq.from.M`
      font-size: 48px;
      line-height: 1.3;
    `}
  `,
  titleM: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 24px;
    font-weight: ${fontWeights.bold};
    line-height: 1.3;

    ${mq.from.M`
      font-size: 32px;
      line-height: 1.2;
    `}
  `,
  titleS: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: ${fontWeights.bold};
    line-height: 1.33;

    ${mq.from.M`
      font-size: 24px;
    `}
  `,
  quote: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 14px;
    font-weight: ${fontWeights.regular};
    line-height: 1.3;

    ${mq.from.M`
      font-size: 16px;
    `}
  `,
  subtitle: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: ${fontWeights.regular};
    line-height: 1.5;

    ${mq.from.M`
      font-size: 24px;
      line-height: 1.33;
    `};
  `,
  body: css`
    margin: 0 0 24px;
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: ${fontWeights.regular};
    line-height: 1.5;

    ${mq.from.M`
      font-size: 18px;
      line-height: 1.33;
    `}
  `,
  bodyBold: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: ${fontWeights.medium};
    line-height: 1.37;

    ${mq.from.M`
    font-size: 18px;
    line-height: 1.2;
  `}
  `,
  subject: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: ${fontWeights.regular};
  `,
  caption: css`
    font-family: ${fonts.primary};
    font-size: 12px;
    font-weight: ${fontWeights.regular};
    line-height: 1.33;

    ${mq.from.M`
      font-size: 14px;
    `}
  `,
  captionBold: css`
    font-family: ${fonts.primary};
    font-size: 12px;
    font-weight: ${fontWeights.bold};
    line-height: 1.33;

    ${mq.from.M`
      font-size: 14px;
    `}
  `,
  accent: css`
    font-family: ${fonts.primary};
    font-size: 14px;
    font-weight: ${fontWeights.bold};
    line-height: 1.37;

    ${mq.from.M`
      font-size: 16px;
      line-height: 1.2;
    `}
  `,
  accentLink: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: ${fontWeights.bold};
    line-height: 1.5;
    letter-spacing: 0.02em;

    ${mq.from.M`
      font-size: 20px;
      line-height: 1.2;
    `}
  `,
}
