/**
 * localizedLink function is responsible for generating localized paths for navigation within a multilingual website.
 *
 * @param {string} to - The target path or URL to which the generated link should point. This can be either absolute or relative URL.
 * @param {string} locale - The current active language locale. This is generally a two-letter language code (e.g., 'en', 'nl').
 * @param {boolean} isDefault - A flag indicating if the locale passed is the default locale for the website.
 *
 * @returns {string} - The localized URL path.
 *
 * The function works by appending the locale string to the beginning of the 'to' path, if the locale is not the default.
 * If the locale is the default, no change is made to the 'to' path.
 *
 * Special handling is included for the website's index ('/') route. If the 'to' parameter is the index route, the function
 * does not append the locale string, even if the locale is not the default. This is done because generally, the index page of
 * the site in non-default language would be something like '/nl/' and not '/nl'.
 *
 * Example:
 * 1. For a 'to' path of '/about', a 'locale' of 'nl', and 'isDefault' as false, the function returns '/nl/about'.
 * 2. For a 'to' path of '/about', a 'locale' of 'en', and 'isDefault' as true, the function returns '/about'.
 * 3. For a 'to' path of '/', a 'locale' of 'nl', and 'isDefault' as false, the function returns '/nl'.
 */

const localizedLink = (
  to: string | undefined,
  locale: string,
  isDefault: boolean
): string => {
  if (!to) return ""
  if (!locale) return to

  const isIndex = to === "/"
  const path = isDefault ? to : `/${locale}${isIndex ? "" : `${to}`}`

  return path
}

export default localizedLink
