import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Components
import ContentWrapper from "./ContentWrapper"
import Button from "./atoms/Button"
import { useLocale } from "../hooks/useLocale"

interface IProps {
  handleClick: () => void
}

const CookiesNotification: React.FC<IProps> = ({ handleClick }) => {
  const { isDutch } = useLocale()
  const { prismicCookiesDutch, prismicCookiesEnglish } = useStaticQuery(
    graphql`
      query {
        prismicCookiesDutch: prismicCookies(lang: { eq: "nl-nl" }) {
          data {
            text {
              html
            }
            button_label
          }
        }
        prismicCookiesEnglish: prismicCookies(lang: { eq: "en-nl" }) {
          data {
            text {
              html
            }
            button_label
          }
        }
      }
    `
  )

  const prismicCookies = isDutch ? prismicCookiesDutch : prismicCookiesEnglish
  const content = {
    text: prismicCookies.data.text.html,
    buttonLabel: prismicCookies.data.button_label,
  }

  return (
    <Container>
      <StyledContentWrapper>
        <Text dangerouslySetInnerHTML={{ __html: content.text }} />
        <StyledButton onClick={handleClick} label={content.buttonLabel} />
      </StyledContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  z-index: 50;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 0;
  color: ${colors.white};
  background-color: ${colors.purpleDark};
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.from.M`
    flex-direction: row;
  `}
`

const Text = styled.div`
  margin-bottom: 32px;

  > p {
    ${textStyles.caption};

    :last-child {
      margin-bottom: 0;
    }
  }

  ${mq.from.M`
    margin-right: 120px;
    margin-bottom: 0;
  `}
`

const StyledButton = styled(Button)`
  white-space: nowrap;
`

export default CookiesNotification
