import { createContext } from "react"

interface IAppState {
  scrollToAnchor: string | null
  setScrollToAnchor: (anchor: string | null) => void
  hideNav: boolean
  setHideNav: (bool: boolean) => void
}

export default createContext<IAppState | null>(null)
