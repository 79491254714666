import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"

// Context
import AppContext from "../../context/AppContext"

// Styling
import colors from "../../styles/colors"
import textStyles from "../../styles/textStyles"
import mq from "../../styles/breakpoints"

// Utils
import handleAnchorLink from "../../utils/handleAnchorLink"

// Hooks
import { useLocale } from "../../hooks/useLocale"

interface IProps {
  type: "internal" | "external" | "anchor" | "nolink"
  to?: string
  title?: string
  target?: string
  color?: string
  onClick?: () => void
  page?: string
  hasstyling?: boolean
  children?: React.ReactNode
}

const Link: React.FCS<IProps> = ({
  type,
  to,
  title,
  target,
  color = colors.purpleDark,
  onClick,
  page,
  hasstyling = true,
  children,
  className,
}) => {
  const context = useContext(AppContext)
  const { getLocalizedLink } = useLocale()

  return (
    <>
      {type === "internal" && (
        <InternalLink
          to={getLocalizedLink(to || "#")}
          color={color}
          hasstyling={hasstyling ? 1 : 0}
          className={className}
          onClick={onClick}
          activeClassName="active"
          partiallyActive
        >
          {title}
          {children}
        </InternalLink>
      )}
      {type === "external" && (
        <ExternalLink
          href={to}
          target={target}
          color={color}
          hasstyling={hasstyling ? 1 : 0}
          className={className}
          rel="noreferrer"
        >
          {title}
          {children}
        </ExternalLink>
      )}
      {type === "anchor" && (
        <AnchorLink
          onClick={() => {
            handleAnchorLink(
              page as string,
              to as string,
              context?.setScrollToAnchor as (element: string) => void
            )
            if (onClick) {
              onClick()
            }
          }}
          color={color}
          hasstyling={hasstyling ? 1 : 0}
          className={className}
        >
          {title}
          {children}
        </AnchorLink>
      )}
      {type === "nolink" && (
        <NoLink
          onClick={onClick}
          color={color}
          hasstyling={hasstyling ? 1 : 0}
          className={className}
        >
          {title}
          {children}
        </NoLink>
      )}
    </>
  )
}

// Exposing for other purposes like language selection links
export const sharedLinkStyling = css`
  ${textStyles.accent}
  cursor: pointer;
  position: relative;
  text-decoration: none;

  ${({ hasstyling, color }: { hasstyling: number; color: string }) =>
    hasstyling &&
    css`
      color: ${color};
      line-height: 1.5;

      &::after {
        content: "";
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${color};
        opacity: 0;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }

      ${mq.from.M`
        &::after {
          bottom: 3px;
        }
      `}
    `}
`

const InternalLink = styled(GatsbyLink)`
  ${sharedLinkStyling};
`

const ExternalLink = styled.a`
  ${sharedLinkStyling};
`

const AnchorLink = styled.div`
  ${sharedLinkStyling};
`

const NoLink = styled.div`
  ${sharedLinkStyling};
`

export default Link
