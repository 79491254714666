import colors from "./colors"

export const VARIANTS = {
  primary: {
    bg: colors.purpleLight,
    textColor: colors.white,
    hover: { bg: colors.irisBlueDark, textColor: colors.white },
    disabled: { bg: colors.disabled, textColor: colors.textDisabled },
  },
  secondary: {
    bg: colors.irisBlue,
    textColor: colors.purpleLight,
    hover: { bg: colors.purpleLight, textColor: colors.white },
    disabled: { bg: colors.disabled, textColor: colors.textDisabled },
  },
  tertiary: {
    bg: colors.white,
    textColor: colors.purpleLight,
    hover: { bg: colors.irisBlue, textColor: colors.purpleLight },
    disabled: { bg: colors.disabled, textColor: colors.textDisabled },
  },
  ghost: {
    bg: colors.transparent,
    textColor: colors.textDark,
    hover: { bg: colors.irisBlue, textColor: colors.textDark },
    disabled: { bg: colors.disabled, textColor: colors.textDark },
  },
  download: {
    bg: colors.white,
    textColor: colors.textDark,
    hover: { bg: colors.irisBlue, textColor: colors.purpleLight },
    disabled: { bg: colors.disabled, textColor: colors.textDark },
  },
}
