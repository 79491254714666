import React, { useState } from "react"

// Context
import AppContext from "./AppContext"

interface IProps {
  children: React.ReactNode
}

const ContextWrapper: React.FC<IProps> = ({ children }) => {
  const [scrollToAnchor, setScrollToAnchor] = useState<string | null>(null)
  const [hideNav, setHideNav] = useState<boolean>(false)

  return (
    <AppContext.Provider
      value={{
        scrollToAnchor,
        setScrollToAnchor: (anchor) => setScrollToAnchor(anchor),
        hideNav,
        setHideNav: (bool) => setHideNav(bool),
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default ContextWrapper
