import React from "react"
import i18n from "../../config/i18n"

// Utils
import localizedLink from "../utils/localizedLink"

interface ILocale {
  default: boolean
  path: string
  locale: string
  siteLanguage: string
  ogLang: string
}

export const LocaleContext = React.createContext<
  | {
      locale: ILocale
      getLocalizedLink: (to: string) => string
      isDutch: boolean
      isEnglish: boolean
    }
  | undefined
>(undefined)

interface ILocaleProviderProps {
  children: React.ReactNode
  locale: keyof typeof i18n
}

const LocaleProvider: React.FC<ILocaleProviderProps> = ({
  children,
  locale,
}) => {
  const _locale = i18n[locale]
  const isDutch = _locale?.locale === "nl-nl" ? true : false

  const getLocalizedLink = (to: string): string => {
    return localizedLink(to, _locale?.path, _locale?.default)
  }

  return (
    <LocaleContext.Provider
      value={{
        locale: _locale,
        isDutch,
        isEnglish: !isDutch,
        getLocalizedLink: (to: string) => getLocalizedLink(to),
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export default LocaleProvider
