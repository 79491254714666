import React from "react"

// Types
import { IIconProps } from "./Icon"

const Download: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 15 16"
    className={className}
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m12.78 9.459-4.773 4.773a.75.75 0 0 1-1.06 0L2.174 9.459a.75.75 0 1 1 1.06-1.06l3.493 3.492V0h1.5v11.89l3.493-3.492a.75.75 0 1 1 1.06 1.06Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 16H0v-1.5h15V16Z"
      fill={color}
    />
  </svg>
)

export default Download
